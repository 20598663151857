import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ProductDesignMumbaiFurniture = props => (
  <Layout>
    <Helmet>
      <title>Mumbai street furniture</title>
      <meta
        name="Furniture design for the outdoor space"
        content="Product Design"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.mumbaicover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">PRODUCT DESIGN</span>
            <h1>mumbai street furniture.</h1>
            <h3>
              A family of street furniture that makes the streets of Mumbai,
              India feel nice and inviting for the public. It provides a safe
              place for people to walk, rest and experience community living
              through street comfort plus convenience.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  People have the perception that the street of Mumbai, India
                  are foul smelling, dirty, unattractive and unsafe. The idea
                  was to design a family of street furniture elements that were
                  functional, aesthetically pleasing and true to Indian
                  sensibilities, thus overall resulting in improving the quality
                  & safety of street life.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2014
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 6 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Design of furniture for the
                  outdoor space
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Individual project
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> User behaviour observation &
                  interviews, conceptualisation, sketching of ideas, 3D
                  modelling & technical drafting, final 3D rendering of the
                  product.
                </span>
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.mumbai_challenge.childImageSharp.fluid} />
            </div>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
            <p className="full-text">
              Qualitative research was carried out by observing and interviewing
              people living in different cities. This activity was carried out
              by me personally visiting different cities in Italy such as Rome,
              Bologna, Florence & Milan.
            </p>

            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  fluid={
                    props.data.mumbai_process_daytime.childImageSharp.fluid
                  }
                />
              </div>
              <div className="img right">
                <Img
                  fluid={
                    props.data.mumbai_process_daytime2.childImageSharp.fluid
                  }
                />
              </div>
            </div>
            <div className="image">
              <Img
                className="project-process-images"
                fluid={props.data.mumbai_process_atnight.childImageSharp.fluid}
                alt="Mumbai Picture streets at night"
                title="Mumbai Picture streets at night"
              />
            </div>
            <p className="full-text">
              While at the same time cities of Bangalore, Pune & Mumbai were
              also explored to identify the indian design environment and
              sensibilities. This research allowed me to determine certain
              design influences and inspirations which lead to consolidating an
              overall design language.
            </p>

            <Img
              className="project-process-images"
              fluid={
                props.data.mumbai_process_inspiration.childImageSharp.fluid
              }
              alt="Mumbai Inspiration"
              title="Mumbai Inspiration"
            />

            <p className="full-text">
              Core design aims for each individual elements were then
              established and the different street elements were designed
              accordingly.
            </p>

            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides1.childImageSharp.fluid
                  }
                  alt="Mumbai Process Bench Sketch"
                  title="Mumbai Process Bench Sketch"
                />

                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides2.childImageSharp.fluid
                  }
                  alt="Mumbai Process Bench"
                  title="Mumbai Process Bench"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides3.childImageSharp.fluid
                  }
                  alt="Mumbai Process Dustbin sketch"
                  title="Mumbai Process Dustbin sketch"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides4.childImageSharp.fluid
                  }
                  alt="Mumbai Process Dustbin"
                  title="Mumbai Process Dustbin"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides5.childImageSharp.fluid
                  }
                  alt="Mumbai Process Streetlight sketch"
                  title="Mumbai Process Streetlight sketch"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides6.childImageSharp.fluid
                  }
                  alt="Mumbai Process Streetlight"
                  title="Mumbai Process Streetlight"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides7.childImageSharp.fluid
                  }
                  alt="Mumbai Process Cycle rack sketch"
                  title="Mumbai Process Cycle rack sketch"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides8.childImageSharp.fluid
                  }
                  alt="Mumbai Process Cycle rack"
                  title="Mumbai Process Cycle rack"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides9.childImageSharp.fluid
                  }
                  alt="Mumbai Process Drinking Fountain sketch"
                  title="Mumbai Process Drinking Fountain sketch"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides10.childImageSharp.fluid
                  }
                  alt="Mumbai Process Drinking Fountain"
                  title="Mumbai Process Drinking Fountain"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides11.childImageSharp.fluid
                  }
                  alt="Mumbai Process bus stop sketch"
                  title="Mumbai Process bus stop sketch"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides12.childImageSharp.fluid
                  }
                  alt="Mumbai Process bus stop"
                  title="Mumbai Process bus stop"
                />
                <Img
                  className="project-process-images"
                  fluid={
                    props.data.mumbai_process_slides13.childImageSharp.fluid
                  }
                  alt="Mumbai Process bus stop night"
                  title="Mumbai Process bus stop night"
                />
              </Slider>
            </div>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <h3>
                The final result was the design of various street furniture
                elements (bench, dustbin, street lamp, bicycle stand and a bus
                stop) that would look like a family of products when placed in a
                street environment.
              </h3>

              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    A Bench which provided a place for people to sit, relax and
                    rest. It had a modular assembly and was designed in a way
                    that prevented the collection of rainwater. Set of Dustbins
                    that not only allowed efficient waste collection &
                    management but was also easy to clean and maintain. An
                    aesthetically pleasing Street Lamp inspired by yoga forms
                    which had ambient & focused lighting thus promoting street
                    safety at night.
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    A Bicycle Stand designed with a clever built-in locking
                    system to reduce theft and encourage bicycle usage in the
                    city. Seating & waiting areas of the Bus Stops were designed
                    with the main aim of ensuring an efficient flow of people at
                    all times. Whereas interactive screens ensure people
                    remained entertained while waiting. Finally the design was
                    made inclusive by designing disabled friendly areas.
                  </p>
                </div>
              </div>
            </div>
            <Img
              className="project-process-images"
              fluid={props.data.mumbai_outcome.childImageSharp.fluid}
              alt="Mumbai furniture family"
              title="Mumbai furniture family"
            />
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/work"> my work</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/product-design-hop-philips">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hop.</div>
                <div className="text">
                  An interactive & playful street element to promote awareness
                  and values of the brand Philips Lighting
                </div>
                <div className="tags">
                  <ul>
                    <li>PRODUCT DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                  A seamless parking & charging solution for Mercedes Benz &
                  Smart Electric Car owners living in urban areas.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProductDesignMumbaiFurniture

export const fluidMumbaiFurnitureImage = graphql`
  fragment fluidMumbaiFurnitureImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    mumbaicover: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/cover/mumbai-cover-image.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_challenge: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/challenge/01_mumbai-design-aim.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_daytime: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/02_mumbai-daytime.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_daytime2: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/03_mumbai-daytime2.JPG"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_atnight: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/04_mumbai-streets-at-night.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_inspiration: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/05_mumbai-inspiration.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides1: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/06_mumbai-bench sketch.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides2: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/07_mumbai-bench.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides3: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/08_mumbai-dustbin-sketch.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides4: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/09_mumbai-dustbin.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides5: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/10_mumbai-streetlight-sketch.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides6: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/11_mumbai-streetlight.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides7: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/12_mumbai-cycle-rack-sketch.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides8: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/13_mumbai-cycle-rack.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides9: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/14_mumbai-drinking-fountain-sketch.JPG"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides10: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/15_mumbai-drinking-fountain.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides11: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/16_mumbai-bus-stop-sketch.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides12: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/17_mumbai-bus-stop-interior.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_process_slides13: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/process/18_mumbai-bus-stop-night.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    mumbai_outcome: file(
      relativePath: {
        eq: "projects/product/mumbai-street-furniture/outcome/19_mumbai-family.jpg"
      }
    ) {
      ...fluidMumbaiFurnitureImage
    }
    project1: file(relativePath: { eq: "projects/8.png" }) {
      ...fluidMumbaiFurnitureImage
    }
    project2: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidMumbaiFurnitureImage
    }
  }
`
